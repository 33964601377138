<template>
  <!--  eslint-disable-next-line-->
  <div class="item-html" v-html="meta.content" data-test="html-item" />
</template>

<script>
export default {
  name: 'HTMLItem',
  props: {
    meta: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.item-html :last-child {
  padding-bottom: 0;
}
.item-html :first-child {
  padding-top: 0;
}
</style>
