<template>
  <VRow
    class="item-buttons__row"
  >
    <VCol
      v-if="skipButtonShow && canBeSkipped"
      cols="6"
    >
      <TTBtn
        color="tt-secondary"
        block
        large
        :disabled="disabled || loading"
        :data-test-label="dataTestSkip"
        @click="handleSkip"
      >
        {{ $t('items.skip') }}
      </TTBtn>
    </VCol>
    <VCol
      v-if="submitButtonShow"
      cols="6"
    >
      <TTBtn
        :color="submitButtonColor"
        large
        block
        :disabled="disabled || !submitUnlock"
        :loading="loading"
        :data-test-label="dataTestSubmit"
        @click="handleSubmit"
      >
        {{ submitButtonText || $t('items.submit') }}
      </TTBtn>
    </VCol>
    <VCol
      v-if="$slots.custom"
      cols="6"
    >
      <slot name="custom" />
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'ItemButtons',
  props: {
    skipButtonShow: {
      type: Boolean,
      default: true,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    submitUnlock: {
      type: Boolean,
      default: true,
    },
    submitButtonShow: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: null,
    },
    submitButtonColor: {
      type: String,
      default: 'tt-primary',
    },
    dataTestSubmit: {
      type: String,
      default: 'item-btn-submit',
    },
    dataTestSkip: {
      type: String,
      default: 'item-btn-skip',
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('submit');
    },
    handleSkip() {
      this.$emit('skip');
    },
  },
};
</script>

<style scoped>
.item-buttons__row{
  margin-top: 4px;
}
</style>
