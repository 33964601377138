import i18n from '@/plugins/vue-i18n/index';

import {
  V_ANSWER_LEN, V_CHAR, V_ENTER_ANSWER, V_HEIGHT, V_IMAGE, V_LESS, V_MORE, V_PIXEL, V_WIDTH,
} from '@/helpers/constants';

const getValidators = (validators) => {
  if (validators) {
    return Object.entries(validators).reduce((accumulator, [key, value]) => {
      if (key === 'min') {
        if (value === 1) {
          accumulator.push((v) => !!v || `${i18n.t(V_ENTER_ANSWER)}`);
        } else {
          accumulator.push((v) => v.length > value
            || `${i18n.t(V_ANSWER_LEN)} ${i18n.t(V_LESS)} ${i18n.tc(V_CHAR, value)}`);
        }
      } else if (key === 'max') {
        accumulator.push((v) => v.length <= value
          || `${i18n.t(V_ANSWER_LEN)} ${i18n.t(V_MORE)} ${i18n.tc(V_CHAR, value)}`);
      } else if (key === 'max_width') {
        if (value > 0) {
          accumulator.push((v) => v.width <= value
            || `${i18n.t(V_WIDTH)} ${i18n.t(V_IMAGE)} ${i18n.t(V_LESS)} ${i18n.tc(V_PIXEL, value)}`);
        }
      } else if (key === 'max_height') {
        if (value > 0) {
          accumulator.push((v) => v.height <= value
            || `${i18n.t(V_HEIGHT)} ${i18n.t(V_IMAGE)} ${i18n.t(V_LESS)} ${i18n.tc(V_PIXEL, value)}`);
        }
      }
      return accumulator;
    }, []);
  }
  return [];
};

export default getValidators;
