<template>
  <div
    class="rate-item-container"
  >
    <VRow>
      <VCol
        cols="12"
        class="rate-item-title tt-text-body-1"
        data-test="rate-item-question"
      >
        {{ meta.question }}
      </VCol>
    </VRow>
    <VRow class="rate-list mx-n1 mt-1">
      <VCol
        v-if="isSlider"
        class="px-0 pt-16 pb-4 slider-wrapper"
      >
        <VSlider
          v-model="selectedIndex"
          :color="'tt-light-mono-24'"
          :track-color="'tt-light-mono-24'"
          :thumb-color="'black'"
          :thumb-size="0"
          :max="10"
          :min="1"
          thumb-label="always"
          :readonly="isSkipped || completed"
          hide-details
          @input="onInputSlider"
        >
          <template #thumb-label="{ value }">
            <div :class="['slider-thumb', 'd-flex', 'justify-center', 'align-center', activeColor, fixedClassThumb]">
              <VIcon
                v-if="noSelectedIndex"
                color="tt-white"
                size="24"
              >
                fas fa-arrows-alt-h
              </VIcon>
              <span
                v-else
                class="tt-text-title-2 tt-light-mono-0--text"
              >{{ value }}</span>
            </div>
          </template>
        </VSlider>
      </VCol>
      <VCol
        v-for="item in items"
        v-else
        :key="item.id"
        class="rate-list-item px-1"
      >
        <TTTooltip
          bottom
          max-width="300"
          :disabled="disabled || item.active || completed"
        >
          <template #activator="{ on, attrs }">
            <TTBtn
              :class="{ 'tt-light-mono-4' : !isChat, 'cursor-default' : disabled || completed }"
              x-large
              block
              :color="item.active ? item.activeColor : 'tt-light-mono-8'"
              v-bind="attrs"
              :data-test-label="`rate-item-btn-${item.id}`"
              v-on="on"
              @click="selectItem(item.id)"
            >
              <VIcon
                v-if="isSmile"
                :color="item.active ? 'tt-light-mono-0' : 'tt-light-mono-24'"
                :size="32"
              >
                {{ item.active ? item.activeIcon : item.noActiveIcon }}
              </VIcon>

              <span
                v-else
                class="tt-text-title-2 tt-light-mono-24--text"
                :class="{ 'tt-light-mono-0--text' : item.active }"
              >
                {{ item.activeNumber }}
              </span>
            </TTBtn>
          </template>

          <span
            data-test="list-item-tooltip-text"
            class="list-item-tooltip-text"
          >
            {{ item.text }}
          </span>
        </TTTooltip>
      </VCol>
    </VRow>

    <VRow
      v-if="!noSelectedIndex && selectedItem && selectedItem.text"
      class="mt-1"
    >
      <VCol cols="12">
        <div
          class="rate-item-description-container tt-light-mono-8 px-5 py-4 tt-text-body-1"
          :class="{ 'tt-light-mono-4' : !isChat }"
          data-test="rate-item-description-container"
        >
          {{ selectedItem.text }}
        </div>
      </VCol>
    </VRow>

    <VRow
      v-if="isAnonymousCheckboxShow"
      class="mt-1"
    >
      <VCol cols="12">
        <TTCheckbox
          v-model="isAnonymous"
          :label="$t('items.rate.anonymous_answer')"
          color="tt-light-mono-24"
          class="rate-item-anonymous mt-0"
          data-test-label="rate-item-checkbox-anonymous"
        />
      </VCol>
    </VRow>

    <VRow
      v-if="isSendedAsAnonymous"
      class="mt-1"
    >
      <VCol cols="12">
        <div
          class="d-flex"
        >
          <VIcon
            class="mx-1"
            color="tt-light-mono-46"
            :size="15"
            data-test="rate-item-icon-anonymous"
          >
            fas fa-mask
          </VIcon>
          <span
            data-test="rate-item-answer-anonymous"
            class="tt-light-mono-46--text tt-text-body-2"
          >
            {{ $t('items.rate.anonymous_answer_done') }}
          </span>
        </div>
      </VCol>
    </VRow>

    <VRow
      v-if="isShowComment && !completed"
      class="mt-1"
    >
      <VCol cols="12">
        <TTTextarea
          v-model="comment"
          :disabled="disabled"
          :placeholder="$t('items.rate.placeholder')"
          class="rate-item-comment"
          data-test-label="rate-item-textarea-comment"
        />
      </VCol>
    </VRow>

    <VRow
      v-if="isShowTextComment"
      class="mt-1"
    >
      <VCol cols="12">
        <div
          class="rate-item-description-container tt-light-mono-8 px-5 py-4 tt-text-body-1"
          :class="{ 'tt-light-mono-4' : !isChat }"
          data-test="rate-item-comment"
        >
          <span class="tt-light-mono-64--text">{{ $t('items.rate.placeholder') }}:</span> {{ comment }}
        </div>
      </VCol>
    </VRow>

    <ItemButtons
      v-if="!isSkipped && !completed"
      :disabled="disabled"
      :loading="loading"
      :can-be-skipped="canBeSkipped"
      :skip-button-show="!isSkipped"
      :submit-unlock="isSubmitUnlock"
      :submit-button-show="!isSkipped"
      data-test-submit="rate-item-btn-submit"
      data-test-skip="rate-item-btn-skip"
      @skip="skip"
      @submit="submit"
    />
  </div>
</template>

<script>
import { SMILES, NUMBERS6_COLORS, NUMBERS10_COLORS } from '@/components/items/constants';
import { RATE_TYPE_SMILE } from '@/helpers/constants';

import ItemButtons from '@/components/items/ItemButtons.vue';

export default {
  name: 'RateItem',
  components: { ItemButtons },
  props: {
    meta: {
      type: Object,
      default: () => {},
    },
    isChat: {
      type: Boolean,
      default: false,
    },
    result: {
      type: [Object, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSkipped: {
      type: Boolean,
      default: false,
    },
    canBeSkipped: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAnonymous: false,
      comment: '',
      selectedIndex: -1,
      noSelectedIndex: false,
    };
  },

  computed: {
    isSlider() {
      return !this.isSmile && this.meta.validate.max === 10;
    },
    items() {
      let result = [];
      if (this.isSmile) {
        result = SMILES;
      } else {
        const { max } = this.meta.validate;
        const colors = (max === 10 ? NUMBERS10_COLORS : NUMBERS6_COLORS).slice(0, max);
        result = colors.map((color, i) => ({
          id: i + 1,
          active: false,
          activeNumber: i + 1,
          activeColor: color,
        }));
      }
      return result.map((item) => ({
        ...item,
        text: this.meta.action.text[item.id - 1],
        active: this.selectedIndex === item.id,
      }));
    },
    isSmile() {
      return this.meta.type === RATE_TYPE_SMILE;
    },
    selectedItem() {
      return this.items.find((el) => el.id === this.selectedIndex);
    },
    activeColor() {
      return this.noSelectedIndex ? 'tt-light-mono-46' : this.selectedItem?.activeColor;
    },
    isAnonymousCheckboxShow() {
      return !this.isChat && this.meta.is_anonymous && !this.completed;
    },
    isSendedAsAnonymous() {
      return !this.isChat && this.completed && this.result?.rate.isAnonymous;
    },
    isShowComment() {
      return this.meta.has_comment && this.selectedItem;
    },
    isShowTextComment() {
      return this.isShowComment && this.completed && this.comment && !this.isSkipped;
    },
    completed() {
      return !!this.result || this.isSkipped;
    },
    fixedClassThumb() {
      let className = '';
      if (this.selectedIndex === 1) {
        className = 'thumb-fix-left';
      } else if (this.selectedIndex === this.items.length) {
        className = 'thumb-fix-right';
      }
      return className;
    },
    isSubmitUnlock() {
      return !!this.selectedItem && !this.noSelectedIndex;
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      if (this.completed && this.result) {
        this.selectedIndex = Number(this.result.rate.value);
        this.comment = this.result.rate.comment;
      }
      this.setNoActiveSlider();
    },
    setNoActiveSlider() {
      if (this.isSlider && this.selectedIndex === -1) {
        this.selectedIndex = 5;
        this.noSelectedIndex = true;
      }
    },
    selectItem(i) {
      if (this.disabled || this.completed) {
        return;
      }
      this.selectedIndex = i;
    },
    submit() {
      const { isAnonymous, comment } = this;

      const payload = {
        value: this.selectedIndex,
        isAnonymous,
        comment,
      };

      this.$emit('submit', payload);
    },
    skip() {
      this.selectedIndex = -1;
      this.setNoActiveSlider();
      this.$emit('skip');
    },
    onInputSlider() {
      this.noSelectedIndex = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-tooltip-text, .rate-item-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.rate-item-description-container {
  border-radius: 12px;
  -webkit-line-clamp: 2;

}

.rate-item-comment {
  ::v-deep {
    textarea {
      height: 124px;
    }
  }
}
.cursor-default{
  cursor: default;
}
.slider-wrapper {
  .slider-thumb {
    width: 54px;
    height: 54px;
    border-radius: 4px;
    position: relative;
    bottom: 32px;
  }
  .thumb-fix-left {
    margin-left: 40px;
  }
  .thumb-fix-right {
    margin-right: 40px;
  }
}
</style>
