import {
  ADD_PHOTO, DOCUMENT,
  ENTER_STRING, ONE_WORD, QR_CODE, RATE, SELECT_MANY, SELECT_ONE, VIDEO, VOTE, YES_NO,
} from '@/helpers/constants';

const getResultRequest = ({ type, taskId, id: contentTaskId }, answer, entityType = 'TASK') => {
  const request = {
    result: {
      contentTaskId,
      result: {},
      taskId,
      entity_type: entityType,
    },
  };

  // TODO:
  // add_file: {
  //   content_base64: 'string',
  //     file_id: 0,
  //     name: 'string',
  // },
  // document: {
  //   is_viewed: true,
  // },
  // enter_date: {
  //   value: 'string',
  // },
  // enter_int: {
  //   value: 0,
  // },
  // enter_string: {
  //   value: 'string',
  // },
  // one_word: {
  //   value: 'string',
  // },
  // qr_code: {
  //   value: 'string',
  // },
  // rate: {
  //   comment: 'string',
  //     is_anonymous: true,
  //     value: 0,
  // },
  // select_many: {
  //   values: [
  //     0,
  //   ],
  // },
  // select_one: {
  //   value: 0,
  // },
  // video: {
  //   is_viewed: true,
  // },
  // vote: {
  //   values: [
  //     0,
  //   ],
  // },

  switch (type) {
    case ENTER_STRING: {
      request.result.result = {
        enterString: {
          value: answer,
        },
      };
      break;
    }
    case ADD_PHOTO: {
      request.result.result = {
        add_photo: answer,
      };
      break;
    }
    case RATE: {
      request.result.result = {
        rate: answer,
      };
      break;
    }
    case YES_NO: {
      request.result.result = {
        yes_no: {
          value: answer,
        },
      };
      break;
    }
    case SELECT_ONE: {
      request.result.result = {
        select_one: {
          value: answer,
        },
      };
      break;
    }
    case SELECT_MANY: {
      request.result.result = {
        select_many: {
          values: answer,
        },
      };
      break;
    }
    case ONE_WORD: {
      request.result.result = {
        one_word: {
          value: answer.toLowerCase(),
        },
      };
      break;
    }
    case QR_CODE: {
      request.result.result = {
        qr_code: {
          value: answer,
        },
      };
      break;
    }
    case DOCUMENT: {
      request.result.result = {
        document: {
          is_viewed: true,
        },
      };
      break;
    }
    case VIDEO: {
      request.result.result = {
        video: {
          is_viewed: true,
        },
      };
      break;
    }
    case VOTE: {
      request.result.result = {
        vote: {
          values: answer,
        },
      };
      break;
    }
    default:
      break;
  }
  return request;
};

export default getResultRequest;
