export const MAP_ZOOM = 17;
export const VIDEO_TYPE_YOUTUBE = 'youtube';
export const VIDEO_TYPE_VIMEO = 'vimeo';
export const VIDEO_TYPE_KINESCOPE = 'kinescope';
export const MAP_ASPECT_RATIO = '1.68';
export const MAP_SUBMIT_BUTTON_COLOR = 'tt-secondary';
export const WORD_ITEM_LEN = 5;
export const VIDEO_ASPECT_RATIO = 16 / 9;
export const SMILES = [
  {
    id: 1,
    active: false,
    activeIcon: 'fas fa-angry',
    activeColor: 'tt-light-red',
    noActiveIcon: 'fal fa-angry',
  },
  {
    id: 2,
    active: false,
    activeIcon: 'fas fa-frown',
    activeColor: 'tt-light-orange',
    noActiveIcon: 'fal fa-frown',
  },
  {
    id: 3,
    active: false,
    activeIcon: 'fas fa-meh',
    activeColor: 'tt-light-orange vibrant',
    noActiveIcon: 'fal fa-meh',
  },
  {
    id: 4,
    active: false,
    activeIcon: 'fas fa-smile',
    activeColor: 'tt-light-yellow',
    noActiveIcon: 'fal fa-smile',
  },
  {
    id: 5,
    active: false,
    activeIcon: 'fas fa-laugh',
    activeColor: 'tt-light-green vibrant',
    noActiveIcon: 'fal fa-laugh',
  },
];

export const NUMBERS6_COLORS = [
  'tt-light-red',
  'tt-light-orange',
  'tt-light-orange vibrant',
  'tt-light-yellow',
  'tt-light-green vibrant',
  'tt-light-green',
];
export const NUMBERS10_COLORS = [
  'tt-light-red',
  'tt-light-red',
  'tt-light-red vibrant',
  'tt-light-red vibrant',
  'tt-light-yellow',
  'tt-light-yellow',
  'tt-light-green vibrant',
  'tt-light-green vibrant',
  'tt-light-green',
  'tt-light-green',
];

export const ACCEPTED_IMAGE_TYPE = 'image/png,image/x-png,image/jpeg';
